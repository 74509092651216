import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/fede/source/largoplacismo/src/components/layout.js";
import LinkButton from "../../components/link-button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Si has leído acerca de algunas de las ideas del largoplacismo y te han parecido convincentes, puede que te interese involucrarte de manera más significativa. A continuación te sugerimos algunas ideas.`}</p>
    <h2 {...{
      "id": "elige-una-carrera-con-impacto",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#elige-una-carrera-con-impacto",
        "aria-label": "elige una carrera con impacto permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Elige una carrera con impacto`}</h2>
    <p>{`Tanto si tienes la suerte de poder usar tu carrera profesional para hacer el bien y te estás planteando cambiar de aires como si estás pensando qué hacer después de la universidad, la organización sin ánimo de lucro `}<a parentName="p" {...{
        "href": "https://80000hours.org/"
      }}>{`80,000 Hours`}</a>{` puede ayudarte a decidir. 80,000 Hours ofrece orientación profesional gratuita, y en su sitio web hay una increíble cantidad de recursos para aprender más sobre las áreas de trabajo largoplacistas.`}</p>
    <LinkButton to='https://80000hours.org/' mdxType="LinkButton">Visita <b>80,000 Hours</b></LinkButton>
    <p>{`Te aconsejamos que leas este artículo primero:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://80000hours.org/make-a-difference-with-your-career/"
        }}><em parentName="a">{`This is your most important decision`}</em></a>{` (Esta es tu decisión más importante). Aquí se explica por qué tu carrera profesional es la mejor oportunidad de marcar la diferencia y cómo puedes aprovecharla al máximo.`}</li>
    </ul>
    <p>{`80,000 Hours también `}<a parentName="p" {...{
        "href": "https://80000hours.org/podcast/"
      }}>{`produce un pódcast`}</a>{` (en inglés) donde encontrarás conversaciones en profundidad acerca de los problemas más acuciantes del mundo y qué podemos hacer para resolverlos. Muchos de los episodios del pódcast tratan sobre temas largoplacistas.`}</p>
    <p>{`Multitud de organizaciones largoplacistas están contratando personal o lo harán próximamente. Puedes buscar empleo en todo el mundo en este `}<a parentName="p" {...{
        "href": "https://80000hours.org/job-board/"
      }}>{`listado de ofertas de trabajo`}</a>{` (en inglés), que incluye oportunidades profesionales especialmente prometedoras para mejorar el futuro a largo plazo.`}</p>
    <p>{`En `}<a parentName="p" {...{
        "href": "https://80000hours.org/speak-with-us/"
      }}>{`este enlace`}</a>{` puedes solicitar orientación profesional individualizada de 80,000 Hours (en inglés).`}</p>
    <p>{`Si tienes una idea de proyecto que podría suponer algún avance en un área de trabajo largoplacista (como llevar a cabo una investigación o crear un grupo estudiantil), quizá puedas recibir apoyo del fondo para el futuro a largo plazo `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/funds/far-future"
      }}>{`Long-Term Future Fund`}</a>{`, que concede subvenciones en nombre de la plataforma de financiación del altruismo eficaz `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/"
      }}>{`Effective Altruism Funds`}</a>{`.`}</p>
    <h2 {...{
      "id": "lleva-a-cabo-investigaciones",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#lleva-a-cabo-investigaciones",
        "aria-label": "lleva a cabo investigaciones permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Lleva a cabo investigaciones`}</h2>
    <p>{`Como paradigma de investigación, el largoplacismo es relativamente nuevo. Queda por desarrollar trabajo fundacional y aún hay margen para realizar avances conceptuales. De hecho, es difícil subestimar la importancia del pensamiento meticuloso e independiente acerca de cómo mejorar el futuro a largo plazo. Descubrir formas de dejar una huella positiva en el mundo es complicado en el mejor de los casos, sobre todo cuando se trata de razonar sobre el futuro lejano. Las buenas ideas para proyectos prácticos son enormemente valiosas, pero tienen un cuello de botella: la investigación de calidad.`}</p>
    <p>{`La investigación motivada por el largoplacismo abarca campos como la economía, la filosofía, la politología, la historia, la inteligencia artificial, el derecho y muchos más. Una buena forma de hacerse una idea de los caminos de investigación más prometedores puede ser leer las agendas de investigación del `}<a parentName="p" {...{
        "href": "https://www.legalpriorities.org/research.html"
      }}>{`Legal Priorities Project`}</a>{`, del `}<a parentName="p" {...{
        "href": "https://globalprioritiesinstitute.org/research-agenda/"
      }}>{`Global Priorities Institute`}</a>{` y, en especial, de la `}<a parentName="p" {...{
        "href": "https://www.forethought.org/longtermism"
      }}>{`Forethought Foundation`}</a>{`.`}</p>
    <p>{`Si eres estudiante, echa un vistazo al proyecto `}<a parentName="p" {...{
        "href": "https://effectivethesis.org/"
      }}>{`Effective Thesis`}</a>{` (Tesis eficaz), que ofrece sesiones de `}<em parentName="p">{`coaching`}</em>{` y orientación gratuitas a estudiantes, desde alumnos de grado hasta doctorandos, que quieran iniciar una carrera en investigación para hacer del mundo un lugar mejor, por ejemplo, a través de trabajo relacionado con el largoplacismo.`}</p>
    <h2 {...{
      "id": "dona",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dona",
        "aria-label": "dona permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Dona`}</h2>
    <p>{`Muchas áreas de trabajo largoplacistas parecen estar limitadas por la falta tanto de personas cualificadas como de ideas para proyectos ambiciosos y escalables. Sin embargo, puedes marcar la diferencia con tu dinero y tu tiempo.`}</p>
    <p>{`Una opción es donar al `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org/charities/longtermism-fund"
      }}>{`Longtermism Fund`}</a>{`, el fondo dedicado al largoplacismo de `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org/"
      }}>{`Giving What We Can`}</a>{`. El Longtermism Fund presta apoyo tanto a organizaciones que trabajan para reducir los riesgos existenciales y catastróficos globales a los que se enfrenta la humanidad como a las que se dedican a mejorar, promover y poner en práctica ideas `}<a parentName="p" {...{
        "href": "https://www.longtermism.com/"
      }}>{`largoplacistas`}</a>{`. Los expertos en concesión de subvenciones de `}<a parentName="p" {...{
        "href": "https://www.longview.org/"
      }}>{`Longview Philanthropy`}</a>{`, con ayuda del equipo de investigación de Giving What We Can, se encargan de decidir las subvenciones que concede el fondo. Si quieres saber más sobre el Longtermism Fund, `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/f7qAfcKArzYrBG7RB/announcing-the-longtermism-fund"
      }}>{`lee la publicación en la que se anunciaba su creación`}</a>{` (en inglés).`}</p>
    <p>{`También puedes donar al fondo para el futuro a largo plazo `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/funds/far-future"
      }}>{`Long-Term Future Fund`}</a>{` (LTFF), que forma parte de la plataforma de financiación `}<a parentName="p" {...{
        "href": "https://funds.effectivealtruism.org/"
      }}>{`Effective Altruism Funds`}</a>{`. El LTFF otorga subvenciones pequeñas y medianas a personas y organizaciones que buscan atajar los riesgos catastróficos globales o promover ideas largoplacistas. Una diferencia entre el LTFF y el Longtermism Fund es que el LTFF concede con más frecuencia subvenciones a proyectos menos consolidados y más especulativos.`}</p>
    <p>{`Los fondos más grandes no son la única forma de donar. De hecho, en ciertos casos podrías tener `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/cjH2puDzAFrtrrThQ/despite-billions-of-extra-funding-small-donors-can-still%233__Small_donors_can_sometimes_beat_large_donors_in_terms_of_cost_effectiveness"
      }}>{`una ventaja`}</a>{` si eres un donante más pequeño. Por ejemplo, algunas organizaciones no pueden aceptar grandes subvenciones de fundaciones. Un ejemplo es `}<a parentName="p" {...{
        "href": "https://forum.effectivealtruism.org/posts/Btm562wDNEuWXj9Gk/guarding-against-pandemics"
      }}>{`Guarding Against Pandemics`}</a>{` (Protección frente a pandemias, o GAP), que defiende la importancia del trabajo en bioseguridad en EE. UU. desde un punto de vista políticamente independiente.`}</p>
    <p>{`Si eres un gran filántropo y te estás planteando donar una cantidad de dinero considerable, `}<a parentName="p" {...{
        "href": "https://www.longview.org/"
      }}>{`Longview Philanthropy`}</a>{` puede ayudarte a diseñar y ejecutar una estrategia a tu medida. La ayuda que prestan es gratuita e independiente, y está revisada por expertos externos.`}</p>
    <p>{`También puedes unirte a otros largoplacistas que se han comprometido a ayudar a otras personas con sus donaciones eficaces, canalizadas a través de `}<a parentName="p" {...{
        "href": "https://www.givingwhatwecan.org/"
      }}>{`Giving What We Can`}</a>{`.`}</p>
    <LinkButton to='https://www.givingwhatwecan.org/' mdxType="LinkButton">Infórmate más sobre <b>Giving What We Can</b></LinkButton>
    <p>{`Por último, muchos largoplacistas están vinculados estrechamente al proyecto más amplio del altruismo eficaz, que busca usar la razón y la evidencia para hacer el mayor bien posible.`}</p>
    <LinkButton to='https://www.altruismoeficaz.org/' mdxType="LinkButton">Infórmate más sobre el <b>altruismo eficaz</b></LinkButton>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      